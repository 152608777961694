import { Component, OnInit } from "@angular/core";
import { GeneralService } from "../../services/general.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private generalService: GeneralService) {}
  genericDetails: any = [];

  ngOnInit(): void {
    this.generalService.getGenericDetailsData().subscribe((data) => {
      this.genericDetails = data;
    });
  }
}
