<div class="container-fluid">
  <div class="row">
    <div class="col-12 p-0">
      <owl-carousel [options]="bannerCarousel" [carouselClasses]="['owl-theme', 'sliding', 'homeBanner']">
        <div class="banner">
          <img src="/assets/images/slide1.jpg" alt=""/>
          <div class="banner_caption bc1">
            <h2 class="italic mb-3">Winter Sale - 20%</h2>
            <h4>on order over ₹100</h4>
            <div class="button1">Discover Your next book</div>
          </div>
        </div>
        <div class="banner">
          <img src="/assets/images/slide2.jpg" alt=""/>
          <div class="banner_caption bc2">
            <h2 class="mb-3">Meeting with <span class="italic">Prasanth Sanna</span></h2>
            <h6>Enjoy the meetand great with your<br/> favorite author</h6>
            <div class="button1">More Info</div>
          </div>
        </div>
        <div class="banner">
          <img src="/assets/images/slide3.jpg" alt=""/>
          <div class="banner_caption bc1">
            <h2 class="italic mb-3">Winter Sale - 20%</h2>
            <h4>on order over ₹100</h4>
            <div class="button1">Discover Your next book</div>
          </div>
        </div>      
      </owl-carousel>
    </div>
  </div>
</div>

<div class="container-fluid cmnPadding coming_soon">
  <div class="container">
    <div class="row">
      <h2 class="col-12 heading"><span class="italic">Publish</span> With Us</h2>
      
      <div class="col-md-4">
        <div class="clearRow pointer" routerLink="/publish-with-us">
          <img class="imgRes" src="/assets/images/cs-book1.jpeg" alt=""/>
          <div class="col-12 cs_box yellowBg">
            <h4>20 books</h4>
            <p>to Help You Bring <br/>up a Child</p>
            <!-- <a class="more_info" href="javascript:;">More info ></a> -->
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="clearRow pointer" routerLink="/publish-with-us">
          <img class="imgRes" src="/assets/images/cs-book2.jpeg" alt=""/>
          <div class="col-12 cs_box fadeGreenBg">
            <h4>20 books</h4>
            <p>to Help You Learn <br/>to Cook</p>
            <!-- <a class="more_info" href="javascript:;">More info ></a> -->
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="clearRow pointer" routerLink="/publish-with-us">
          <img class="imgRes" src="/assets/images/cs-book3.jpeg" alt=""/>
          <div class="col-12 cs_box redBg">
            <h4>20 books</h4>
            <p>to Help You Be in<br/>Good Mood</p>
            <!-- <a class="more_info" href="javascript:;">More info ></a> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid cmnPadding">
  <div class="container">
    <div class="row">
      <h2 class="col-12 heading"><span class="italic">What’s</span> on Sale</h2>
      <div class="col-12 p-0 text-center mob_onsale">
        <owl-carousel [options]="whatOnSaleCarousel" [carouselClasses]="['owl-theme', 'sliding', 'onSaleBanner']">
          <div class="items" routerLink="/details">
            <img src="/assets/images/book1.jpeg" alt=""/>
            <div class="description">
              <h3>The forest</h3>
              <p>Allan Green</p>
              <h6><span class="ruppee_symbol">₹</span>250</h6>
            </div>
          </div>

          <div class="items" routerLink="/details">
            <img src="/assets/images/book2.jpeg" alt=""/>
            <div class="description">
              <h3>The son</h3>
              <p>Angelika Glow</p>
              <h6><span class="ruppee_symbol">₹</span>250</h6>
            </div>
          </div> 
          
          <div class="items" routerLink="/details">
            <img src="/assets/images/book1.jpeg" alt=""/>
            <div class="description">
              <h3>The forest</h3>
              <p>Allan Green</p>
              <h6><span class="ruppee_symbol">₹</span>250</h6>
            </div>
          </div>

          <div class="items" routerLink="/details">
            <img src="/assets/images/book2.jpeg" alt=""/>
            <div class="description">
              <h3>The son</h3>
              <p>Angelika Glow</p>
              <h6><span class="ruppee_symbol">₹</span>250</h6>
            </div>
          </div>
        </owl-carousel>
        <a class="button1 mt-4" routerLink="/books">Discover more book</a>
      </div>     

    </div>
  </div>
</div>

<div class="container-fluid aboutBg" style="background-image: url('/assets/images/about-banner.jpeg');">
  <h2 class="heading"><span class="italic">Inspire</span> Daily Reading</h2>
  <h4>Visit Our Blog and Page Find Out Daily<br/>Inspiration Quotes from the best Authors</h4>
  <div class="button1 mt-5">Visit our blog</div>
</div>