<div class="container-fluid page_banner" style="background-image: url('/assets/images/category-banner.jpeg');">
  <h2 class="heading">Inspire Daily Reading</h2>
  <h4>Visit Our Blog and Page Find Out Daily<br/>Inspiration Quotes from the best Authors</h4>
  <div class="button1 mt-5">Visit our blog</div>
</div>

<div class="container-fluid cmnPadding">
  <div class="container">
    <div class="row category_list">

      <div class="col-md-3">
        <div class="items" routerLink="/details">
          <img src="/assets/images/book2.jpeg" alt=""/>
          <div class="description">
            <h3>The son</h3>
            <h6><span class="ruppee_symbol">₹</span> 250</h6>
            <!-- <a class="button2 mt-3" routerLink="/details">Details</a> -->
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="items" routerLink="/details">
          <img src="/assets/images/book1.jpeg" alt=""/>
          <div class="description">
            <h3>The forest</h3>
            <h6><span class="ruppee_symbol">₹</span>250</h6>
            <!-- <a class="button2 mt-3" routerLink="/details">Details</a> -->
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="items" routerLink="/details">
          <img src="/assets/images/book2.jpeg" alt=""/>
          <div class="description">
            <h3>The son</h3>
            <h6><span class="ruppee_symbol">₹</span> 250</h6>
            <!-- <a class="button2 mt-3" routerLink="/details">Details</a> -->
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="items" routerLink="/details">
          <img src="/assets/images/book1.jpeg" alt=""/>
          <div class="description">
            <h3>The forest</h3>
            <h6><span class="ruppee_symbol">₹</span>250</h6>
            <!-- <a class="button2 mt-3" routerLink="/details">Details</a> -->
          </div>
        </div>
      </div><div class="col-md-3">
        <div class="items" routerLink="/details">
          <img src="/assets/images/book2.jpeg" alt=""/>
          <div class="description">
            <h3>The son</h3>
            <h6><span class="ruppee_symbol">₹</span> 250</h6>
            <!-- <a class="button2 mt-3" routerLink="/details">Details</a> -->
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="items" routerLink="/details">
          <img src="/assets/images/book1.jpeg" alt=""/>
          <div class="description">
            <h3>The forest</h3>
            <h6><span class="ruppee_symbol">₹</span>250</h6>
            <!-- <a class="button2 mt-3" routerLink="/details">Details</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
