<div class="container-fluid page_banner" style="background-image: url('/assets/images/category-banner.jpeg');">
  <h2 class="heading">Publish With Us</h2>
  <h4></h4>
</div>

<div class="container-fluid cmnPadding">
  <div class="container abt_section">
    <div class="row">
      <div class="col-md-4">
        <img class="img_responsive" src="/assets/images/aboutus.jpeg" alt=""/>
      </div>
      <div class="col-md-8">
        <h2 class="heading abt_title">Publish With Us</h2>
        <p>Our mission is to foster a universal passion for reading by partnering with authors to help create stories and communicate ideas that inform, entertain, and inspire, and to connect them with readers everywhere. We are an international home to nearly 400 editorially and creatively independent publishing imprints.</p>
      </div>
    </div>
  </div>
</div>