<div class="container">
  <div class="row">
    <mat-tab-group mat-align-tabs="center" class="col-md-12 product_details">
      <mat-tab *ngFor="let bdtTab of productDetails[0].bookDetailTabs; let i = index" [label]="bdtTab.tabLabel">
        <ng-container *ngFor="let bdt of bdtTab.bookDetails">
          <div class="row m-auto">
            <div class="col-md-5 mob_book_slide">
              <owl-carousel [options]="booksCarousel" [carouselClasses]="['owl-theme', 'sliding', 'book_slides']">

                <ng-container *ngFor="let item of bdt.bookImageCollection; let i = index">
                  <div class="items"><img [src]="item.image" alt="" /></div>
                </ng-container>
                <!-- <div class="items"><img src="/assets/images/book2.jpeg" alt="" /></div>
              <div class="items"><img src="/assets/images/book1.jpeg" alt="" /></div> -->
              </owl-carousel>
              <div class="clearRow book_details pl-3">
                <h5>{{ bdt.imprintLabel }}: {{ bdt.imprintDetail }}</h5>
                <h5>{{ bdt.publishedLabel }}: {{ bdt.publishedDetail }}</h5>
                <h5>{{ bdt.isbnLabel }}: {{ bdt.isbnDetail }}</h5>
                <h5>{{ bdt.lengthLabel }}: {{ bdt.lengthDetail }}</h5>
                <h5>{{ bdt.mrpLabel }} : <span class="ruppee_symbol">₹</span>{{ bdt.mrpDetail }}</h5>
              </div>
            </div>
            <div class="col-md-6 ml-5 product_description">
              <h3>{{ bdt.bookNmae }}</h3>
              <h4>{{ bdt.bookSubTitle }}</h4>
              <h4 style="color: #797979;">{{ bdt.authorName }}</h4>
              <p>{{ bdt.description }}</p>

              <h4 class="mt-4 fw400 mb-3">{{ bdt.shareTitle }}</h4>

              <share-buttons [theme]="'circles-dark'" [include]="['facebook','twitter','linkedin','whatsapp','email']"
                [show]="5" [size]="-4" [url]="'https://www.ficstorpublishers.in/'" [title]="'Ficstor Publisher'"
                [autoSetMeta]="false"></share-buttons>

              <h4 class="mt-4 fw400 mb-3">{{ bdt.buyOptionTitle }}</h4>
              <mat-tab-group mat-align-tabs="left" class="col-md-12 p-0 d-flex prefer_format">
                <mat-tab *ngFor="let bot of bdt.buyOptionTabs; let i = index" [label]="bot.tabLabel">
                  <div class="buy_option">
                    <ng-container *ngFor="let item of bot.buyOptionList; let i = index">
                      <a href="#"><img [src]="item.image" alt="" />
                        <h4>{{ item.mrpLabel }}: <span><span class="ruppee_symbol">₹</span>{{ item.mrpDetails }}</span>
                        </h4>
                        <h5>{{ item.dealPriceLabel }}: <span class="ruppee_symbol">₹</span> {{ item.dealPriceDetails }}
                        </h5>
                        <h4>{{ item.youSaveLabel }}: <span class="ruppee_symbol">₹</span> {{ item.youSaveDetails }}</h4>
                      </a>
                    </ng-container>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </ng-container>
      </mat-tab>

      <mat-tab *ngFor="let adtTab of productDetails[0].authorDetailTabs; let i = index" [label]="adtTab.tabLabel">
        <ng-container *ngFor="let adt of adtTab.authordetails">
          <div class="row m-auto">
            <div class="col-12 auther_description mb-5">
              <h3>{{ adt.authorName }}</h3>
              <p>{{ adt.authorDescription }}</p>
            </div>
            <h2 class="col-12 heading heading_left">{{ adt.otherTitle }} <span class="italic">{{
                adt.otherTitleSpan }}</span></h2>

            <div class="col-12">
              <owl-carousel [options]="alsoReadCarousel" [carouselClasses]="['owl-theme', 'sliding', 'onSaleBanner']">

                <div class="items" *ngFor="let item of adt.otherBookList; let i = index">
                  <a [routerLink]="item.ctaLink">
                    <img [src]="item.image" alt="" />
                    <div class="description">
                      <h3>{{ item.name }}</h3>
                      <h6><span class="ruppee_symbol">₹</span>{{ item.price }}</h6>
                    </div>
                  </a>
                </div>

              </owl-carousel>
            </div>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>