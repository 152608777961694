<div class="container-fluid header_top">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-8">
        <p>Welcome to Ficstor Publisher!</p>
      </div>
      <div class="col-md-6 col-4 d-flex justify-content-end social_media">
        <!-- <p class="login_link">login</p> -->
        <a target="_blank" href="javascript:;"><img src="/assets/images/facebook.svg" alt=""/></a>
        <a target="_blank" href="javascript:;"><img src="/assets/images/twitter.svg" alt=""/></a>
        <a target="_blank" href="javascript:;"><img src="/assets/images/instagram.svg" alt=""/></a>
        <a target="_blank" href="javascript:;"><img src="/assets/images/linkedin.svg" alt=""/></a>
        <!-- <a target="_blank" href="javascript:;"><img src="/assets/images/youtube.svg" alt=""/></a> -->
      </div>
    </div>
  </div>
</div>

<div class="container-fluid header_main">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-6">
        <a routerLink="/home"><img class="logo" src="/assets/images/logo-horizontal.svg" alt="" /></a>
      </div>
      <div class="col-md-8 col-6">
        <nav class="navbar navbar-expand-lg navbar-light menu_bar">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item active" data-toggle="collapse" data-target="#navbarNav">
                <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target="#navbarNav">
                <a class="nav-link" routerLink="/about-us" routerLinkActive="active">About Us</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target="#navbarNav">
                <a class="nav-link" routerLink="/books" routerLinkActive="active">Books</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target="#navbarNav">
                <a class="nav-link" routerLink="/publish-with-us" routerLinkActive="active">Publish With Us</a>
              </li>
              <li class="nav-item" data-toggle="collapse" data-target="#navbarNav">
                <a class="nav-link" routerLink="/contact-us" routerLinkActive="active">Contact Us</a>
              </li>
            </ul>
          </div>
        </nav>

      </div>
    </div>
  </div>
</div>