import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ficstor';
  isSticky = false;
  toTop = false;


  constructor(private router: Router) { }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 50;
    this.toTop = window.pageYOffset >= 300;
  }

  scrollToTop(){
    window.scroll(0,0);
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      window.scrollTo(0, 0)
    });
  }
  
}
