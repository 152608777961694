export const booksDetail = [
  {
    banner: {
      title: "Inspire Daily Reading",
      subTitle:
        "Visit Our Blog and Page Find Out Daily Inspiration Quotes from the best Authors",
      ctaText: "Visit Our Blog",
      ctaLink: "",
      image: "/assets/images/category-banner.jpeg",
    },

    bookList: [
      {
        _id: 1,
        ctaLink: "/details",
        image: "/assets/images/book2.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 2,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 3,
        ctaLink: "/details",
        image: "/assets/images/book2.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 4,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 5,
        ctaLink: "/details",
        image: "/assets/images/book2.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 6,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 7,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 8,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        price: "250",
      },
      {
        _id: 9,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        price: "250",
      },
    ],
  },
];
