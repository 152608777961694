import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { OwlModule } from 'ngx-owl-carousel';
import {MatTabsModule} from '@angular/material/tabs';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { HomeComponent } from './home/home.component';
import { CategoriesComponent } from './categories/categories.component';
import { ProductsComponent } from './products/products.component';
import { CountactUsComponent } from './countact-us/countact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PublishWithUsComponent } from './publish-with-us/publish-with-us.component';


@NgModule({
  declarations: [
    HomeComponent,
    CategoriesComponent,
    ProductsComponent,
    CountactUsComponent,
    AboutUsComponent,
    TermsConditionsComponent,
    PublishWithUsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OwlModule,
    MatTabsModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
  ],
  exports: [
    SharedModule,
    OwlModule,
    MatTabsModule,
  ],
  entryComponents: [], 
  providers: [],
})
export class FicstorWebModule { }
