<div class="container-fluid footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <img class="logo_footer" src="/assets/images/logo-horizontal.svg" alt=""/>
        <p class="mt-4">This delightful book is the latest in the series of Ladybird books which have been specially planned to help grown-ups with the world about them…</p>
      </div>
      <div class="col-md-3">
        <h3>Address</h3>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
      </div>
      <div class="col-md-3 social_main">
        <h3>E-mail</h3>
        <a href="mailto:">dummy@123.com</a>
        <h3 class="mt-3">Connect With Us</h3>
        <div class="social_media">
          <a target="_blank" href="javascript:;"><img src="/assets/images/facebook.svg" alt=""/></a>
          <a target="_blank" href="javascript:;"><img src="/assets/images/twitter.svg" alt=""/></a>
          <a target="_blank" href="javascript:;"><img src="/assets/images/instagram.svg" alt=""/></a>
          <a target="_blank" href="javascript:;"><img src="/assets/images/linkedin.svg" alt=""/></a>
          <!-- <a target="_blank" href="javascript:;"><img src="/assets/images/youtube.svg" alt=""/></a> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 copy_rights">
      <p> Ficstor &copy; 2021. All rights reserved.</p>
    </div>
  </div>
</div>