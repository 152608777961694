<div class="container-fluid">
  <div class="row">
    <div class="col-12 p-0">
      <owl-carousel [options]="bannerCarousel" [carouselClasses]="['owl-theme', 'sliding', 'homeBanner']">
        <div class="banner" *ngFor="let item of homeDetails[0].banner; let i = index">
          <img [src]="item.image" alt="" />
          <div class="banner_caption bc1">
            <h2 class="italic mb-3">{{ item.title }}</h2>
            <h4>{{ item.subTitle }}</h4>
            <div class="button1" [routerLink]="item.ctaLink">{{ item.ctaText }}</div>
          </div>
        </div>
      </owl-carousel>
    </div>
  </div>
</div>

<div class="container-fluid cmnPadding coming_soon">
  <div class="container">
    <div class="row">
      <h2 class="col-12 heading"><span class="italic">{{ homeDetails[0].publishTitle }}</span> {{
        homeDetails[0].publishTitleSpan }}</h2>

      <div class="col-md-4" *ngFor="let item of homeDetails[0].publishDetails; let i = index">
        <div class="clearRow pointer" [routerLink]="item.ctaLink">
          <img class="imgRes" [src]="item.image" alt="" />
          <div class="col-12 cs_box" [ngClass]="item.bgClassName">
            <h4>{{ item.title }}</h4>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid cmnPadding">
  <div class="container">
    <div class="row">
      <h2 class="col-12 heading"><span class="italic">{{ homeDetails[0].saleTitle }}</span> {{
        homeDetails[0].saleTitleSpan }}</h2>
      <div class="col-12 p-0 text-center mob_onsale">
        <owl-carousel [options]="whatOnSaleCarousel" [carouselClasses]="['owl-theme', 'sliding', 'onSaleBanner']">
          <ng-container *ngFor="let item of homeDetails[0].saleBookList; let i = index">
            <div class="items" [routerLink]="item.ctaLink">
              <img [src]="item.image" alt="" />
              <div class="description">
                <h3>{{ item.name }}</h3>
                <p>{{ item.desc }}</p>
                <h6><span class="ruppee_symbol">₹</span>{{ item.price }}</h6>
              </div>
            </div>
          </ng-container>

        </owl-carousel>
        <a class="button1 mt-4" [routerLink]="homeDetails[0].saleCtaLink">{{
          homeDetails[0].saleCtaText }}</a>
      </div>

    </div>
  </div>
</div>

<div class="container-fluid aboutBg" [style.backgroundImage]="'url(' + homeDetails[0].middleBanner.image + ')'">
  <h2 class="heading"><span class="italic">{{ homeDetails[0].middleBanner.title }}</span> {{
    homeDetails[0].middleBanner.titleSpan }}</h2>
  <h4>{{ homeDetails[0].middleBanner.description }}</h4>
  <div class="button1 mt-5" [routerLink]="homeDetails[0].middleBanner.ctaLink">{{ homeDetails[0].middleBanner.ctaText }}
  </div>
</div>