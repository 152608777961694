import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { SharedModule } from "./shared/shared.module";
import { FicstorWebModule } from "./ficstor-web/ficstor-web.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { HttpClientModule } from "@angular/common/http";
import { GeneralService } from "./services/general.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FicstorWebModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [GeneralService],
  bootstrap: [AppComponent],
})
export class AppModule {}
