import { Component, OnInit } from "@angular/core";
import { GeneralService } from "../../services/general.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(private generalService: GeneralService) {}
  homeDetails: any = [];

  bannerCarousel: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    nav: false,
    navSpeed: 5000,
    navText: ["", ""],
    autoWidth: false,
    items: 1,
    autoplay: true,
  };

  whatOnSaleCarousel: any = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    nav: true,
    navSpeed: 1500,
    navText: ["", ""],
    autoWidth: false,
    autoplay: false,
    items: 5,
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 3 },
      940: { items: 5 },
    },
  };

  ngOnInit(): void {
    this.generalService.getHomeDetailsData().subscribe((data) => {
      this.homeDetails = data;
    });
  }
}
