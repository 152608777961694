import { Component, OnInit } from "@angular/core";
import { GeneralService } from "../../services/general.service";

@Component({
  selector: "app-publish-with-us",
  templateUrl: "./publish-with-us.component.html",
  styleUrls: ["./publish-with-us.component.scss"],
})
export class PublishWithUsComponent implements OnInit {
  constructor(private generalService: GeneralService) {}
  publishWithUs: any = [];

  ngOnInit(): void {
    this.generalService.getPublishWithUsData().subscribe((data) => {
      this.publishWithUs = data;
    });
  }
}
