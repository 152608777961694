export const productDetails = [
  {
    bookDetailTabs: [
      {
        _id: 1,
        tabLabel: "Book",
        bookDetails: [
          {
            bookImageCollection: [
              {
                _id: 1,
                image: "/assets/images/book1.jpeg",
              },
              {
                _id: 2,
                image: "/assets/images/book2.jpeg",
              },
              {
                _id: 3,
                image: "/assets/images/book1.jpeg",
              },
            ],
            imprintLabel: "Imprint",
            imprintDetail: "India Penguin",
            publishedLabel: "Published",
            publishedDetail: "Dec/2000",
            isbnLabel: "ISBN",
            isbnDetail: "9780140299700",
            lengthLabel: "Length",
            lengthDetail: "212 Pages",
            mrpLabel: "MRP",
            mrpDetail: "299.00",

            bookNmae: "Sholay",
            bookSubTitle: "The Making Of A Classic",
            authorName: "Anupama Chopra, Chopra Anupama",
            description:
              "National Award Winner: ‘Best Book On Film’ Year 2000. Film Journalist Anupama Chopra Tells The Fascinating Story Of How A Four-Line Idea Grew To Become The Greatest Blockbuster Of Indian Cinema. Starting With The Tricky Process Of Casting, Moving On To The Actual Filming Over Two Years In A Barren, Rocky Landscape, And Finally The First Weeks After The Film’S Release When The Audience Stayed Away And The Trade Declared It A Flop, This Is A Story As Dramatic And Entertaining As Sholay Itself. With The Skill Of A Consummate Storyteller, Anupama Chopra Describes Amitabh Bachchan’S Struggle To Convince The Sippys To Choose Him, An Actor With Ten Flops Behind Him, Over The Flamboyant Shatrughan Sinha; The Last-Minute Confusion Over Dates That Led To Danny Dengzongpa’S Exit From The Fim, Handing The Role Of Gabbar Singh To Amjad Khan; And The Budding Romance Between Hema Malini And Dharmendra During The Shooting That Made The Spot Boys Some Extra Money And Almost Killed Amitabh.",
            shareTitle: "Share",
            shareButtonUrl: "https://www.ficstorpublishers.in/",
            shareButtonTitle: "Ficstor Publisher",
            buyOptionTitle: "BUYING OPTIONS",
            buyOptionTabs: [
              {
                _id: 1,
                tabLabel: "Paperback",
                buyOptionList: [
                  {
                    _id: 1,
                    url: "",
                    image: "/assets/images/flipkart_logo.jpeg",
                    mrpLabel: "M.R.P",
                    mrpDetails: "399.00",
                    dealPriceLabel: "Deal Price",
                    dealPriceDetails: "299.00",
                    youSaveLabel: "You Save",
                    youSaveDetails: "100.00",
                  },
                  {
                    _id: 2,
                    url: "",
                    image: "/assets/images/amazon_logo.jpeg",
                    mrpLabel: "M.R.P",
                    mrpDetails: "399.00",
                    dealPriceLabel: "Deal Price",
                    dealPriceDetails: "299.00",
                    youSaveLabel: "You Save",
                    youSaveDetails: "100.00",
                  },
                ],
              },
              {
                _id: 2,
                tabLabel: "eBook",
                buyOptionList: [
                  {
                    _id: 1,
                    url: "",
                    image: "/assets/images/flipkart_logo.jpeg",
                    mrpLabel: "M.R.P",
                    mrpDetails: "399.00",
                    dealPriceLabel: "Deal Price",
                    dealPriceDetails: "299.00",
                    youSaveLabel: "You Save",
                    youSaveDetails: "100.00",
                  },
                  {
                    _id: 2,
                    url: "",
                    image: "/assets/images/amazon_logo.jpeg",
                    mrpLabel: "M.R.P",
                    mrpDetails: "399.00",
                    dealPriceLabel: "Deal Price",
                    dealPriceDetails: "299.00",
                    youSaveLabel: "You Save",
                    youSaveDetails: "100.00",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    authorDetailTabs: [
      {
        _id: 1,
        tabLabel: "Author",
        authordetails: [
          {
            authorName: "Anupama Chopra",
            authorDescription:
              "Anupama Chopra was born as Anupama Chandra in Calcutta (now Kolkata) to a Bengali family. Besides this book, she has penned A Truly Motivational Book, One Book For Life Success, King of Bollywood: Shah Rukh Khan and the Seductive World of Indian Cinema, Dilwale Dulhania Le Jayenge (Book), and First Day First Show: Writings From the Bollywood Trenches. As a film critic, she reviewed movies for NDTV 24/7 and The Hindustan Times. Chopra grew up in Mumbai and Hong Kong. She graduated from St. Xavier's College, Mumbai, with a BA in English Literature and earned her MA in Journalism from Northwestern University's Medill School of Journalism. She won the Harrington Award for magazine journalism while at Medill. She is married to famous Bollywood filmmaker and director, Vidhu Vinod Chopra.",
            otherTitle: "Other Books of",
            otherTitleSpan: "Author",
            otherBookList: [
              {
                _id: 1,
                ctaLink: "/details",
                image: "/assets/images/book2.jpeg",
                name: "The forest",
                price: "250",
              },
              {
                _id: 2,
                ctaLink: "/details",
                image: "/assets/images/book1.jpeg",
                name: "The son",
                price: "250",
              },
              {
                _id: 3,
                ctaLink: "/details",
                image: "/assets/images/book2.jpeg",
                name: "The forest",
                price: "250",
              },
              {
                _id: 4,
                ctaLink: "/details",
                image: "/assets/images/book1.jpeg",
                name: "The son",
                price: "250",
              },
            ],
          },
        ],
      },
    ],
  },
];
