import { Component, OnInit } from "@angular/core";
import { GeneralService } from "../../services/general.service";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  constructor(private generalService: GeneralService) {}
  aboutusData: any = [];

  ngOnInit(): void {
    this.generalService.getAboutUsData().subscribe((data) => {
      this.aboutusData = data;
    });
  }
}
