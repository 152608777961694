<div class="container-fluid page_banner" [style.backgroundImage]="'url(' + booksDetail[0].banner.image + ')'">
  <h2 class="heading">{{ booksDetail[0].banner.title }}</h2>
  <h4>{{ booksDetail[0].banner.subTitle }}</h4>
  <div class="button1 mt-5" [routerLink]="booksDetail[0].banner.ctaLink">{{ booksDetail[0].banner.ctaText }}</div>
</div>

<div class="container-fluid cmnPadding">
  <div class="container">
    <div class="row category_list">

      <div class="col-md-3" *ngFor="let item of booksDetail[0].bookList; let i = index">
        <div class="items" [routerLink]="item.ctaLink">
          <img [src]="item.image" alt="" />
          <div class="description">
            <h3>{{ item.name }}</h3>
            <h6><span class="ruppee_symbol">₹</span> {{ item.price }}</h6>
            <!-- <a class="button2 mt-3" routerLink="/details">Details</a> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</div>