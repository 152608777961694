import { Injectable, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpResponse,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import * as CONFIG from "../config.const";
import { genericDetails } from "src/app/mock-data/generic.mock";
import { homeDetails } from "src/app/mock-data/home.mock";
import { aboutUs } from "src/app/mock-data/aboutus.mock";
import { publishWithUs } from "src/app/mock-data/publish.mock";
import { booksDetail } from "src/app/mock-data/books.mock";
import { productDetails } from "src/app/mock-data/detail.mock";

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  constructor(public http: HttpClient) {}
  uri = CONFIG.apiUrl;
  updateInSafetyMeasures = new EventEmitter<[]>();
  fetchQuestions = new EventEmitter<[]>();

  getGenericDetailsData(): Observable<any> {
    return of(genericDetails);
  }
  getHomeDetailsData(): Observable<any> {
    return of(homeDetails);
  }
  getAboutUsData(): Observable<any> {
    return of(aboutUs);
  }
  getPublishWithUsData(): Observable<any> {
    return of(publishWithUs);
  }
  getBooksDetailData(): Observable<any> {
    return of(booksDetail);
  }
  getProductDetailsData(): Observable<any> {
    return of(productDetails);
  }
}
