import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as CONFIG from './config.const';

import { HomeComponent } from './ficstor-web/home/home.component';
import { CategoriesComponent } from './ficstor-web/categories/categories.component';
import { AboutUsComponent } from './ficstor-web/about-us/about-us.component';
import { CountactUsComponent } from './ficstor-web/countact-us/countact-us.component';
import { ProductsComponent } from './ficstor-web/products/products.component';
import { PublishWithUsComponent } from './ficstor-web/publish-with-us/publish-with-us.component';

const prefix = CONFIG.apiPrefix;

const routes: Routes = [
  { path: '', redirectTo: prefix + 'home', pathMatch: 'full' },
  { path: prefix + 'home' , component: HomeComponent },
  { path: prefix + 'books', component: CategoriesComponent },
  { path: prefix + 'about-us', component: AboutUsComponent },
  { path: prefix + 'publish-with-us', component: PublishWithUsComponent },
  { path: prefix + 'contact-us', component: CountactUsComponent },
  { path: prefix + 'details', component: ProductsComponent },
  
  //{ path: '**', redirectTo: prefix + '/page-not-found' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
