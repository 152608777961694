export const aboutUs = [
  {
    banner: {
      title: "Where all we started",
      bannerImage: "/assets/images/category-banner.jpeg",
    },
    title: "About Us",
    subTitle:
      "Ficstor Publishers Private Limited is a dynamic and innovative publishing company based in Bengaluru, established in 2016. Since our inception, we have been dedicated to producing high-quality publications that cater to diverse audiences, ranging from fiction and non-fiction to academic and professional works.",
      descriptions:
      ["Our mission is to discover and nurture talented authors, providing them with a platform to share their stories and ideas with the world. We pride ourselves on our commitment to excellence, ensuring that every book we publish meets the highest standards of quality and craftsmanship. At Ficstor Publishers, we believe in the power of stories to inspire, educate, and entertain. Our team of experienced editors, designers, and marketing professionals work collaboratively to bring each project to life, from manuscript to market.",
      "We are passionate about supporting the literary community and fostering a love for reading. Our catalog features a wide array of genres and subjects, reflecting our commitment to diversity and inclusion in publishing. Our policies are centered around integrity, innovation, and excellence, ensuring that we provide a supportive and respectful environment for our authors and employees alike."],
    aboutusImage: "/assets/images/aboutus.jpeg",
  },
];
