<div class="container-fluid page_banner" [style.backgroundImage]="'url(' + aboutusData[0].banner.bannerImage + ')'">
  <h2 class="heading">{{ aboutusData[0].banner.title }}</h2>
  <h4></h4>
</div>
<div class="container-fluid cmnPadding" *ngFor="let item of aboutusData">
  <div class="container abt_section">
    <div class="row">
      <div class="col-md-4">
        <img class="img_responsive" [src]="item.aboutusImage" alt="" />
      </div>
      <div class="col-md-8">
        <h2 class="heading abt_title">{{ item.title }}</h2>
        <p>{{ item.subTitle }}</p>
      </div>
      <div class="col-md-12 mt-4">
        <ng-container *ngFor="let descp of item.descriptions">
          <p>{{ descp }}</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>