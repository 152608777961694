import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor() { }

  booksCarousel: any = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    nav: true,
    navSpeed: 1500,
    navText: ['', ''],
    autoWidth: false,
    autoplay: false,
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 1 },
      940: { items: 1 },
    },
  }

  alsoReadCarousel: any = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    nav: true,
    navSpeed: 1500,
    navText: ['', ''],
    autoWidth: false,
    autoplay: false,
    items: 5,
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 3 },
      940: { items: 5 },
    },
  }

  ngOnInit(): void {
  }



}
