<div class="container-fluid page_banner" style="background-image: url('/assets/images/category-banner.jpeg');">
  <h2 class="heading">Contact Us</h2>
  <h4></h4>
</div>

<div class="container-fluid cmnPadding contactPadding">
  <div class="container abt_section">
    <div class="row">
      <div class="col-md-12 contactUsMain">
        <h2 class="heading abt_title">Reach Us</h2>

        <h3>Address</h3>
        <p class="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard </p>

        <h3>E-mail</h3>
        <a href="mailto:">dummy@123.com</a>


      </div>
    </div>
  </div>
</div>