export const genericDetails = [
  {
    headerLogo: "/assets/images/logo-horizontal.svg",
    headerLogoCta: "/home",
    welcomeText: "Welcome to Ficstor Publisher!",
    socialMedia: [
      {
        _id: 1,
        socialIcon: "/assets/images/instagram.svg",
        socialUrl: "https://www.instagram.com/ficstorpublishers",
      },
      {
        _id: 2,
        socialIcon: "/assets/images/twitter.svg",
        socialUrl: "https://x.com/ficstor",
      },
      {
        _id: 3,
        socialIcon: "/assets/images/facebook.svg",
        socialUrl: "https://www.facebook.com/ficstorpublishers",
      },
      {
        _id: 4,
        socialIcon: "/assets/images/linkedin.svg",
        socialUrl: "https://www.linkedin.com/company/ficstor-publishers",
      },
      {
        _id: 5,
        socialIcon: "/assets/images/whatsapp.svg",
        socialUrl: "https://wa.me/919790715870",
      },
      //   {
      //     _id: 6,
      //     socialIcon: "/assets/images/youtube.svg",
      //     socialUrl: "",
      //   },
    ],
    menu: [
      {
        _id: 1,
        name: "Home",
        link: "/home",
      },
      {
        _id: 2,
        name: "About Us",
        link: "/about-us",
      },
      {
        _id: 3,
        name: "Books",
        link: "/books",
      },
      {
        _id: 4,
        name: "Publish With Us",
        link: "/publish-with-us",
      },
      {
        _id: 5,
        name: "Contact Us",
        link: "/contact-us",
      },
    ],
    footerLogo: "/assets/images/logo-horizontal.svg",
    footerDescription:
      "Transforming your manuscript into a book has never been easier. We help you publish and make it available to readers around the world.",
    address: "Address",
    addressDetails: "Bengaluru, Karnataka",
    email: "E-mail",
    emailDetails: "contact@ficstor.com",
    phone: "Phone No",
    phoneNo: "+91 9790715870",
    socialTitle: "Connect With Us",
    copyRights: "Ficstor © 2025. All rights reserved.",

    conatctDetailTitle: "Reach Us",
    conatctBanner: {
      title: "Contact Us",
      bannerImage: "/assets/images/category-banner.jpeg",
    },
  },
];
