export const publishWithUs = [
  {
    banner: {
      title: "Publish With Us",
      bannerImage: "/assets/images/category-banner.jpeg",
    },
    title: "Publish With Us",
    subTitle:
      "At Ficstor Publishers, we make publishing simple, affordable, and professional. Whether you're a first-time author or an experienced writer, we provide comprehensive support to bring your book to life while keeping costs optimized.",
    publishLeftImage: "/assets/images/aboutus.jpeg",
    descriptions: [{
      title: "Why Choose Ficstor?",
      content_type: 2,
      content: [{
        title: "End-to-End Publishing",
        decp: "Editing, cover design, ISBN allocation, and distribution.",
      },{
        title: "Cost-Effective Solutions",
        decp: "High-quality publishing at competitive prices.",
      },{
        title: "Wide Distribution",
        decp: "Make your book available on Amazon, Flipkart, and bookstores.",
      },{
        title: "Marketing & Promotion",
        decp: "Increase visibility with strategic book promotion.",
      },{
        title: "Print & E-book Options",
        decp: "Reach a broader audience in multiple formats.",
      }]
    }, {
      title: "Editing & Additional Costs",
      content_type: 1,
      content: ["We understand that every book has unique editing needs. Once we review your manuscript, we will assess the necessary editorial work and inform you of any additional costs before proceeding."]
    }, {
      title: "How It Works",
      content_type: 2,
      content: [{
        title: "Submit Your Manuscript",
        decp: "Share your book with us.",
      },{
        title: "Typesetting & Design",
        decp: "Post editing, we format your book for print and digital versions and create an eye-catching cover.",
      },{
        title: "Publishing & Distribution",
        decp: "Your book is published across major platforms.",
      },{
        title: "Marketing & Sales",
        decp: "We help you reach more readers.",
      }]
    }, {
      title: "Ready to publish?",
      content_type: 1,
      content: ["Drop an email at contact@ficstor.com"]
    }],
  },
];
