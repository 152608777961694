<div class="container-fluid page_banner"
  [style.backgroundImage]="'url(' + genericDetails[0].conatctBanner.bannerImage + ')'">
  <h2 class="heading">{{ genericDetails[0].conatctBanner.title }}</h2>
  <h4></h4>
</div>

<div class="container-fluid cmnPadding contactPadding">
  <div class="container abt_section">
    <div class="row">
      <div class="col-md-12 contactUsMain">
        <h2 class="heading abt_title">{{ genericDetails[0].conatctDetailTitle }}</h2>

        <!-- <h3>{{ genericDetails[0].address }}</h3>
        <p class="">{{ genericDetails[0].addressDetails }}</p> -->

        <h3>{{ genericDetails[0].email }}</h3>
        <a [attr.href]="'mailto:' + genericDetails[0].emailDetails">
          {{ genericDetails[0].emailDetails }}
        </a>
        <h3 class="mt-3">{{ genericDetails[0].phone }}</h3>
        <a [attr.href]="'tel:' + genericDetails[0].phoneNo">
          {{ genericDetails[0].phoneNo }}
        </a>

      </div>
    </div>
  </div>
</div>