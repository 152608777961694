export const homeDetails = [
  {
    banner: [
      {
        _id: 1,
        image: "/assets/images/slide1.jpg",
        title: "Winter Sale - 20%",
        subTitle: "on order over ₹100",
        ctaText: "Discover Your next book",
        ctaLink: "",
      },
      {
        _id: 2,
        image: "/assets/images/slide2.jpg",
        title: "Winter Sale - 20%",
        subTitle: "on order over ₹100",
        ctaText: "More Info",
        ctaLink: "",
      },
      {
        _id: 3,
        image: "/assets/images/slide3.jpg",
        title: "Winter Sale - 20%",
        subTitle: "on order over ₹100",
        ctaText: "Discover Your next book",
        ctaLink: "",
      },
    ],
    publishTitle: "Publish",
    publishTitleSpan: "With Us",

    publishDetails: [
      {
        _id: 1,
        ctaLink: "/publish-with-us",
        image: "/assets/images/cs-book1.jpeg",
        title: "20 books",
        desc: "to Help You Bring up \n a Child",
        bgClassName: "yellowBg",
      },
      {
        _id: 2,
        ctaLink: "/publish-with-us",
        image: "/assets/images/cs-book2.jpeg",
        title: "20 books",
        desc: "to Help You Learn \n to Cook",
        bgClassName: "fadeGreenBg",
      },
      {
        _id: 3,
        ctaLink: "/publish-with-us",
        image: "/assets/images/cs-book3.jpeg",
        title: "20 books",
        desc: "to Help You Be in \n Good Mood",
        bgClassName: "redBg",
      },
    ],
    saleTitle: "What’s",
    saleTitleSpan: "on Sale",
    saleBookList: [
      {
        _id: 1,
        ctaLink: "/details",
        image: "/assets/images/book2.jpeg",
        name: "The forest",
        desc: "Allan Green",
        price: "250",
      },
      {
        _id: 2,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        desc: "Angelika Glow",
        price: "250",
      },
      {
        _id: 3,
        ctaLink: "/details",
        image: "/assets/images/book2.jpeg",
        name: "The forest",
        desc: "Allan Green",
        price: "250",
      },
      {
        _id: 4,
        ctaLink: "/details",
        image: "/assets/images/book1.jpeg",
        name: "The son",
        desc: "Angelika Glow",
        price: "250",
      },
    ],
    saleCtaText: "Discover more book",
    saleCtaLink: "/books",
    middleBanner: {
      image: "/assets/images/about-banner.jpeg",
      title: "Inspire",
      titleSpan: "Daily Reading",
      description:
        "Visit Our Blog and Page Find Out Daily \n Inspiration Quotes from the best Authors",
      ctaText: "Visit our blog",
      ctaLink: "",
    },
  },
];
