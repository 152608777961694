"use strict";

import { environment } from "../environments/environment";
export const apiPrefix = "";
export const imageAssetPrefix =
  "https://s3.ap-south-1.amazonaws.com/inresto-assets/";
export var apiUrl = environment.baseUrl + "/api";
export var adminUrl = environment.baseUrl;

export function setEnvUrls() {
  apiUrl = environment.baseUrl + "/api";
  adminUrl = environment.baseUrl;
}
