<div class="container">
  <div class="row">
    <mat-tab-group mat-align-tabs="center" class="col-md-12 product_details">
      <mat-tab label="Book">
        <div class="row m-auto">
          <div class="col-md-5 mob_book_slide">
            <owl-carousel [options]="booksCarousel" [carouselClasses]="['owl-theme', 'sliding', 'book_slides']">
              <div class="items"><img src="/assets/images/book1.jpeg" alt=""/></div>
              <div class="items"><img src="/assets/images/book2.jpeg" alt=""/></div> 
              <div class="items"><img src="/assets/images/book1.jpeg" alt=""/></div>
            </owl-carousel>
            <div class="clearRow book_details pl-3">
              <h5>Imprint: India Penguin</h5>
              <h5>Published: Dec/2000</h5>
              <h5>ISBN: 9780140299700</h5>
              <h5>Length : 212 Pages</h5>
              <h5>MRP : <span class="ruppee_symbol">₹</span>299.00</h5>
            </div>
          </div>
          <div class="col-md-6 ml-5 product_description">
            <h3>Sholay</h3>
            <h4>The Making Of A Classic</h4>
            <h4 style="color: #797979;">Anupama Chopra, Chopra Anupama</h4>
            <p>National Award Winner: ‘Best Book On Film’ Year 2000.
              Film Journalist Anupama Chopra Tells The Fascinating Story Of How A Four-Line Idea Grew To Become The Greatest Blockbuster Of Indian Cinema. Starting With The Tricky Process Of Casting, Moving On To The Actual Filming Over Two Years In A Barren, Rocky Landscape, And Finally The First Weeks After The Film’S Release When The Audience Stayed Away And The Trade Declared It A Flop, This Is A Story As Dramatic And Entertaining As Sholay Itself. With The Skill Of A Consummate Storyteller, Anupama Chopra Describes Amitabh Bachchan’S Struggle To Convince The Sippys To Choose Him, An Actor With Ten Flops Behind Him, Over The Flamboyant Shatrughan Sinha; The Last-Minute Confusion Over Dates That Led To Danny Dengzongpa’S Exit From The Fim, Handing The Role Of Gabbar Singh To Amjad Khan; And The Budding Romance Between Hema Malini And Dharmendra During The Shooting That Made The Spot Boys Some Extra Money And Almost Killed Amitabh.</p>

            <h4 class="mt-4 fw400 mb-3">Share</h4> 
            <share-buttons [theme]="'circles-dark'"
              [include]="['facebook','twitter','linkedin','whatsapp','email']"
              [show]="5"
              [size]="-4"
              [url]="'https://www.ficstorpublishers.in/'"
              [title]="'Ficstor Publisher'"
              [autoSetMeta]="false"
              ></share-buttons>        
            <h4 class="mt-4 fw400 mb-3">BUYING OPTIONS</h4>
            <mat-tab-group mat-align-tabs="left" class="col-md-12 p-0 d-flex prefer_format">
              <mat-tab label="Paperback">
                <div class="buy_option">
                  <a href="#"><img src="/assets/images/flipkart_logo.jpeg" alt=""/> 
                  <h4>M.R.P.: <span><span class="ruppee_symbol">₹</span>399.00</span></h4>
                  <h5>Deal Price:	<span class="ruppee_symbol">₹</span> 299.00</h5>
                  <h4>You Save:	<span class="ruppee_symbol">₹</span> 100.00</h4>
                  </a>
                  <a href="#"><img src="/assets/images/amazon_logo.jpeg" alt=""/> 
                    <h4>M.R.P.: <span><span class="ruppee_symbol">₹</span>399.00</span></h4>
                  <h5>Deal Price:	<span class="ruppee_symbol">₹</span> 299.00</h5>
                  <h4>You Save:	<span class="ruppee_symbol">₹</span> 100.00</h4>
                  </a>
                </div>
              </mat-tab>
              <mat-tab label="eBook">
                <div class="buy_option">
                  <a href="#"><img src="/assets/images/flipkart_logo.jpeg" alt=""/> 
                    <h4>M.R.P.: <span><span class="ruppee_symbol">₹</span>399.00</span></h4>
                    <h5>Deal Price:	<span class="ruppee_symbol">₹</span> 299.00</h5>
                    <h4>You Save:	<span class="ruppee_symbol">₹</span> 100.00</h4>
                  </a>
                  <a href="#"><img src="/assets/images/amazon_logo.jpeg" alt=""/> 
                    <h4>M.R.P.: <span><span class="ruppee_symbol">₹</span>399.00</span></h4>
                    <h5>Deal Price:	<span class="ruppee_symbol">₹</span> 299.00</h5>
                    <h4>You Save:	<span class="ruppee_symbol">₹</span> 100.00</h4>
                  </a>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Author">
        <div class="row m-auto">
          <div class="col-12 auther_description mb-5">
            <h3>Anupama Chopra</h3>
            <p>Anupama Chopra was born as Anupama Chandra in Calcutta (now Kolkata) to a Bengali family.
              Besides this book, she has penned A Truly Motivational Book, One Book For Life Success, King of Bollywood: Shah Rukh Khan and the Seductive World of Indian Cinema, Dilwale Dulhania Le Jayenge (Book), and First Day First Show: Writings From the Bollywood Trenches. As a film critic, she reviewed movies for NDTV 24/7 and The Hindustan Times. Chopra grew up in Mumbai and Hong Kong. She graduated from St. Xavier's College, Mumbai, with a BA in English Literature and earned her MA in Journalism from Northwestern University's Medill School of Journalism. She won the Harrington Award for magazine journalism while at Medill. She is married to famous Bollywood filmmaker and director, Vidhu Vinod Chopra.</p>
          </div>
          <h2 class="col-12 heading heading_left">Other Books of <span class="italic">Author</span></h2>
          <div class="col-12">
            <owl-carousel [options]="alsoReadCarousel" [carouselClasses]="['owl-theme', 'sliding', 'onSaleBanner']">

              <div class="items">
                <a routerLink="/details">
                  <img src="/assets/images/book1.jpeg" alt=""/>
                  <div class="description">
                    <h3>The forest</h3>
                    <h6><span class="ruppee_symbol">₹</span>250</h6>
                  </div>
                </a>
              </div>

              <div class="items">
                <a routerLink="/details">
                  <img src="/assets/images/book2.jpeg" alt=""/>
                  <div class="description">
                    <h3>The son</h3>
                    <h6><span class="ruppee_symbol">₹</span>250</h6>
                  </div>
                </a>
              </div> 
              
              <div class="items">
                <a routerLink="/details">
                  <img src="/assets/images/book1.jpeg" alt=""/>
                  <div class="description">
                    <h3>The forest</h3>
                    <h6><span class="ruppee_symbol">₹</span>250</h6>
                  </div>
                </a>
              </div>

              <div class="items">
                <a routerLink="/details">
                  <img src="/assets/images/book2.jpeg" alt=""/>
                  <div class="description">
                    <h3>The son</h3>
                    <h6><span class="ruppee_symbol">₹</span>250</h6>
                  </div>
                </a>
              </div>

            </owl-carousel>
          </div>  


        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>