<div class="container-fluid page_banner" [style.backgroundImage]="'url(' + publishWithUs[0].banner.bannerImage + ')'">
  <h2 class="heading">{{ publishWithUs[0].banner.title }}</h2>
  <h4></h4>
</div>

<div class="container-fluid cmnPadding" *ngFor="let item of publishWithUs">
  <div class="container abt_section">
    <div class="row">
      <div class="col-md-4">
        <img class="img_responsive" [src]="item.publishLeftImage" alt="" />
      </div>
      <div class="col-md-8">
        <h2 class="heading abt_title">{{ item.title }}</h2>
        <p>{{ item.subTitle }}</p>
      </div>
      <div class="col-md-12 mt-4">
        <ng-container *ngFor="let descp of item.descriptions">
          <h3>{{ descp.title }}</h3>
          <ng-container *ngIf="descp.content_type == 1">
            <ng-container *ngFor="let content of descp.content">
              <p>{{ content }}</p>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="descp.content_type == 2">
            <ul>
              <ng-container *ngFor="let content of descp.content">
                <li>
                  <ng-container>{{content.title}}</ng-container> - <ng-container>{{content.decp}}</ng-container>
                </li>
              </ng-container>
            </ul>  
            
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>