<div class="container-fluid header_top">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-8">
        <p>{{ genericDetails[0].welcomeText }}</p>
      </div>
      <div class="col-md-6 col-4 d-flex justify-content-end social_media">
        <ng-container *ngFor="let item of genericDetails[0].socialMedia; let i = index">
          <a target="_blank" [attr.href]="item.socialUrl"><img [src]="item.socialIcon" alt="" /></a>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid header_main">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-6">
        <a [routerLink]="genericDetails[0].headerLogoCta"><img class="logo" [src]="genericDetails[0].headerLogo"
            alt="" /></a>
      </div>
      <div class="col-md-8 col-6">
        <nav class="navbar navbar-expand-lg navbar-light menu_bar">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item active" data-toggle="collapse" data-target="#navbarNav"
                *ngFor="let item of genericDetails[0].menu; let i = index">
                <a class="nav-link" [routerLink]="item.link" routerLinkActive="active">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>