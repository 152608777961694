<div class="container-fluid footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <img class="logo_footer" [src]="genericDetails[0].footerLogo" alt="" />
        <p class="mt-4">{{ genericDetails[0].footerDescription }}</p>
      </div>
      <div class="col-md-3">
        <!-- <h3>{{ genericDetails[0].address }}</h3>
        <p>{{ genericDetails[0].addressDetails }}</p> -->
      </div>
      <div class="col-md-3 social_main">
        <h3>{{ genericDetails[0].email }}</h3>
        <a [attr.href]="'mailto:' + genericDetails[0].emailDetails">
          {{ genericDetails[0].emailDetails }}
        </a>
        <!-- <h3 class="mt-3">{{ genericDetails[0].phone }}</h3>
        <a [attr.href]="'tel:' + genericDetails[0].phoneNo">
          {{ genericDetails[0].phoneNo }}
        </a> -->
        <h3 class="mt-3">{{ genericDetails[0].socialTitle }}</h3>
        <div class="social_media">
          <ng-container *ngFor="let item of genericDetails[0].socialMedia; let i = index">
            <a target="_blank" [attr.href]="item.socialUrl"><img [src]="item.socialIcon" alt="" /></a>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 copy_rights">
      <p> {{ genericDetails[0].copyRights }}</p>
    </div>
  </div>
</div>